<template>
    <div class="action-share-request">
        <div class="title" v-html="$translate('ACTION_SHARE_REQUEST_TITLE')" />
        <div class="body">
            <div v-html="body" />
        </div>
    </div>
</template>
<script>
export default {
    name: 'ActionShareRequest',
    props: ['message'],
    computed: {
        user() {
            return this.$store.getters.chat.user
        },
        body() {
            const nameOrNick = this.$nameOrNick(this.$store.getters.chat.user)
            return this.$translate('ACTION_SHARE_REQUEST_BODY').replace(/%s/g, nameOrNick)
        },
    },
}
</script>
